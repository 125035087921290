<template>
  <div>
    <b-row>
      <b-col
        v-for="item in statisticsItems"
        :key="item.subtitle"
        class="mb-2 mb-md-0"
        cols="4"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h3 
                class="mb-0"
                :class="item.textColor"
                style="font-weight: 800;"
              >
                ฿ {{ item.title | currency }}
              </h3>
              <h5 class="text-muted">
                {{ item.subtitle }}
              </h5>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>

    <InvoicesList />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BillSummary', 
  components: {
    InvoicesList: () => import('./components/InvoicesList.vue'),
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['invoices']),
    totalInvoicesAmount() {
      const initialValue = 0;
      if(this.invoices.length > 0) {
        return this.invoices.map((inv) => +inv?.totalAmount || 0).reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          initialValue
        );
      }
      return initialValue;
    },
    statisticsItems() {
      return [
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          textColor: 'text-danger',
          title: this.totalInvoicesAmount,
          subtitle: 'ยอดค้างชำระ',
        },
      ]
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getInvoices']),
    fetchData() {
    //   this.getInvoices({
    //     limit: 10,
    //     skip: '',
    //     search: '',
    //     status: ''
    //   });
    },
  },
}
</script>
